import React from "react";
import Header from "../components/Header";
import { useState } from "react";
import "../styles/gallery.scss";
import Helmet from "react-helmet";
import Tapetsering1 from "../images/Tapetsering1.jpg";
import Tapetsering2 from "../images/Tapetsering2.jpg";
import Tapetsering3 from "../images/Tapetsering3.jpg";
import Tapetsering4 from "../images/Tapetsering4.jpg";
import Tapetsering5 from "../images/Tapetsering5.jpg";
import Tapetsering6 from "../images/Tapetsering6.jpg";
import Tapetsering7 from "../images/Tapetsering7.jpg";

export default function Gallery() {
  const [images] = useState([
    { img: Tapetsering1, alt: "Bild med tapetserat rum" },
    { img: Tapetsering2, alt: "Bild med tapetserat barnrum" },
    { img: Tapetsering3, alt: "Bild med ännu ett tapeserat rum" },
    { img: Tapetsering4, alt: "Bild med ännu ett tapeserat rum" },
    { img: Tapetsering5, alt: "Bild med ännu ett tapeserat rum" },
    {
      img: Tapetsering6,
      alt: "Bild med stor tapetserat hall-rum på ovanvåning",
    },
    {
      img: Tapetsering7,
      alt: "Bild med stor tapetserat hall-rum på ovanvåning från annan vinkel",
    },
  ]);
  const [activeImage, SetActiveImage] = useState("");

  const handleHover = (e) => {
    e.target.classList.add("hoverClass");
  };
  const handleHoverOff = (e) => {
    e.target.classList.remove("hoverClass");
  };

  const handleClick = (e) => {
    SetActiveImage(e);
  };

  return (
    <div className="gallery__container">
      <div className="gallery__wrapper">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Ljungs Måleri & Byggnadsvård</title>
        </Helmet>
        <Header />
        <h1>Här har vi samlat bilder på diverse projekt.</h1>
        <h2>
          Klicka på en bild för att förstora och klicka igen för att återgå
        </h2>

        <div className="gallery__grid">
          {images.map((element) => {
            return (
              <img
                className="anImg"
                src={element.img}
                key={element.img}
                alt={element.alt}
                role="button"
                onMouseLeave={(e) => handleHoverOff(e)}
                onFocus={(e) => handleHover(e)}
                onBlur={(e) => handleHoverOff(e)}
                onMouseOver={(e) => handleHover(e)}
                onClick={() => handleClick(element)}
                onKeyDown={() => handleClick(element)}
                onKeyUp={() => SetActiveImage("")}
              ></img>
            );
          })}
        </div>
        <div className="activeImage">
          {activeImage.length !== 0 ? (
            <div
              onClick={(e) => SetActiveImage("")}
              className="active__image__container"
            >
              <img
                alt={activeImage.alt}
                src={activeImage.img}
                onClick={(e) => SetActiveImage("")}
              ></img>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
